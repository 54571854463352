
import { Service } from '@/services/service'

const PATH = 'ai/assistant/tasks/'

class TaskListService extends Service {
  getTasksList (params) {
    return this.get(PATH + 'list', params)
  }

  remove (params) {
    return this.post(PATH + 'remove', params)
  }

  complete (params) {
    return this.post(PATH + 'complete', params)
  }

  pending (params) {
    return this.post(PATH + 'pending', params)
  }

  getCurrentPendingTasks (params) {
    return this.get(PATH + 'current-pending-tasks', params)
  }
}

export default new TaskListService()
